import React from "react";

function NotFound() {
    return (
        <>
            <h2>OEPS.... DEZE PAGINA IS NIET GEVONDEN</h2>
        </>
    );
}

export default NotFound;