import React from "react";
import './HomePage.css'
import { Link } from "react-router-dom";

function HomePage() {
    return (
        <div>
            <article>
                <section>
                    <div>
                        <h3>level en coin awarded</h3>
                    </div>
                    <div>
                        <h3>times</h3>
                    </div>
                </section>
                <section>
                    <div>
                        <h3> char- screen</h3>
                    </div>
                    <div>
                        <h3> middel text/background</h3>
                    </div>
                    <div>
                        <h3>active quests</h3>
                        <Link to="/">
                            <button type="button">Exit system</button>
                        </Link>
                    </div>
                </section>
                <section>
                    <nav>
                        <ul>
                            <li>Home</li>
                            <li>achievements</li>
                            <li>creations</li>
                            <li>about me</li>
                        </ul>
                    </nav>
                </section>
            </article>
        </div>
    )
}

export default HomePage;